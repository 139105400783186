import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '@Layouts/Default';
import Breadcrumbs from '@components/Breadcrumbs';
import { Container, Row, Col } from '@Omegapoint/components/layout';
import ArticleList from '@components/ArticleList';
import { Text } from '@Omegapoint/components';
import { getTheme } from '@styles/global';
import useStore from '@store';
import LoginModal from '@Omegapoint/components/Auth/LoginModal';
import Spinner from '@components/atoms/Spinner';

const SubCategoryPage = (props) => {
  const { data, location, pageContext } = props;
  const auth = useStore((state) => state.auth);

  if (typeof data === 'undefined') return null;

  const { SiteSettings } = data.allUmbracoMarkets.edges
    .filter((edge) => {
      return (
        edge.node.Culture !== null &&
        edge.node.Culture.countryCode === pageContext.culture
      );
    })
    .map((page) => {
      return page.node.UmbracoContent.Settings;
    })[0];

  const theme = getTheme(SiteSettings?.SiteTheme.toLowerCase());

  return (
    <DefaultLayout
      culture={props.pageContext.culture}
      canonical={data.chSubCategory.canonical ?? props.path}
      title={data.chSubCategory.title}
      description={data?.chSubCategory?.description}
    >
      {process.env.GATSBY_REQUIRE_LOGIN === 'true' && auth.status !== 'in' ? (
        <>
          {auth.status === 'loading' || auth.status === 'none' ? (
            <Spinner />
          ) : (
            <LoginModal culture={props.pageContext.culture} />
          )}
        </>
      ) : (
        <>
          <Container gutter="20">
            <Row gutters={theme.Variables.gutter}>
              <Col>
                <Breadcrumbs path={location.pathname} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Text variant="h2" component="h1" align="center">
              {data.chSubCategory.title}
            </Text>
            <Text variant="p" align="center">
              {data.chSubCategory.description}
            </Text>
            <ArticleList
              categoryIds={[]}
              subCategoryIds={[pageContext.entityId]}
            />
          </Container>
        </>
      )}
    </DefaultLayout>
  );
};

export default SubCategoryPage;

export const query = graphql`
  query SubCategoryPageQuery($entityId: String!, $culture: String!) {
    chSubCategory(
      market: { countryCode: { eq: $culture } }
      entityId: { eq: $entityId }
    ) {
      title
      description
    }
    allUmbracoMarkets {
      edges {
        node {
          Culture {
            countryCode
          }
          UmbracoContent {
            Settings {
              SiteSettings {
                SiteTheme
              }
            }
          }
        }
      }
    }
  }
`;
